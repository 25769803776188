import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/logo.png'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap'

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <>
        <Navbar color="light" light expand="md">
          <Container>
            <NavbarBrand className="logo" tag={Link} to="/">
              <img src={Logo} alt="Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/about-us">
                    About Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/quote">
                    Request a quote
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}
