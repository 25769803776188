import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import Header from './header'
import Footer from './footer'
import '../styles/styles.scss'

const theme = {
  green: `#339902`,
  grey: `#9999AA`,
  darkGrey: `#444448`,
}

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <Helmet
        title="Greens Window Cleaning - Window Cleaning in Chippenham"
        meta={[
          {
            name: 'description',
            content:
              'Greens window cleaning is a family run window cleaning business based in Chippenham. Our pure water systems leave your windows streak free every time.',
          },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
