import React from 'react'
import { Link } from 'gatsby'
import { Row, Col, Container } from 'reactstrap'
import styled from 'styled-components'

const StyledFooter = styled.section`
  background: ${props => props.theme.darkGrey};
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: #ccc;
    }
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style: none;
    margin: 10px 0;
  }
  h3 {
    color: #fff;
  }
`

const Footer = () => (
  <StyledFooter className="py-5 text-center">
    <Container>
      <Row>
        <Col>
          <h3>Contact</h3>
          <p>01249 660268</p>
          <p>
            Greens Window Cleaning
            <br />
            Chippenham
          </p>
        </Col>
        <Col>
          <h3>Links</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us/">About Us</Link>
            </li>
            <li>
              <Link to="/quote/">Request a quote</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
    <Container>
      <p>
        Site created by <a href="https://www.kozocreative.com">Kozo Creative</a>
      </p>
    </Container>
  </StyledFooter>
)

export default Footer
